import { Observable } from 'rxjs';
import { RcgCalendarEventException, RcgEvent, RcgUnassignedEvent, RcgView } from '.';

export abstract class ICalendarService {
  abstract getEvents(view: RcgView, resourceGroupId: number, start: Date, end: Date, search$: Observable<string>): Observable<RcgEvent[]>;
  abstract getEventExceptions(eventId: number): Observable<RcgCalendarEventException[]>;

  abstract addEvent(view: RcgView, record: Record<string, unknown>, isDeleted?: boolean): Promise<void>;
  abstract updateEvent(view: RcgView, record: Record<string, unknown>, isDeleted?: boolean): Promise<void>;
  abstract deleteEvent(record: Record<string, unknown>): Promise<void>;

  abstract getUnassignedEvents(view: RcgView, search$: Observable<string>): Observable<RcgUnassignedEvent[]>;
  abstract assignEvent(
    view: RcgView,
    event: RcgUnassignedEvent,
    resourceGroupId: number,
    resourceId: number,
    start: Date,
    end: Date,
  ): Promise<void>;
  abstract unassignEvent(view: RcgView, event: RcgEvent): Promise<void>;
}
