import { ChangeDetectionStrategy, Component, effect, inject, input } from '@angular/core';
import { CalendarContainerComponent, CustomFormSettings, ICalendarService, RcgResource, RcgView } from '@rcg/calendar';
import { tr } from '@rcg/intl';
import { derivedAsync } from 'ngxtension/derived-async';
import { firstValueFrom, of } from 'rxjs';
import { GqlSimpleCalendarService } from './gql-simple-calendar.service';
import { CalendarFormSettings, GqlCalendarConfig, MapEventData } from './models';

@Component({
  selector: 'rcg-gql-calendar',
  standalone: true,
  imports: [CalendarContainerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ICalendarService,
      useClass: GqlSimpleCalendarService,
    },
  ],
  template: `<rcg-calendar-container [viewData]="viewData()"></rcg-calendar-container>`,
})
export class GqlCalendarComponent {
  readonly config = input<GqlCalendarConfig>();

  private readonly calendarService = inject(ICalendarService);

  constructor() {
    effect(
      () => {
        (this.calendarService as GqlSimpleCalendarService).config.set(this.config());
      },
      { allowSignalWrites: true },
    );
  }

  readonly viewData = derivedAsync<RcgView>(
    async () => {
      const resource: RcgResource = {
        parent_id: null,
        id: 1,
        name: '',
        description: '',
        color: '#000000',
        children: [],
        isParent: true,
        active: true,
        timezone: 'Europe/Ljubljana',
      };

      const config = this.config();
      const view = {
        id: 1,
        show: {
          unassignedEvents: config?.unassigned_events?.show_unassigned_events ?? false,
          resourcePicker: false,
          search: config?.search ?? false,
        },
        name: 'Simple Calendar',
        description: 'Simple calendar view',
        icon: 'event',
        allowed_view_types: config?.allowed_view_types?.length
          ? config!.allowed_view_types!
          : ['Day', 'Week', 'Month', 'Agenda', 'TimelineMonth', 'TimelineWeek', 'TimelineWorkWeek', 'TimelineDay'],
        color_resource_group_id: config?.color_resource_group_id ?? 2,
        default_view_type: config?.default_view_type ?? 'Month',
        interval: config?.interval ?? '01:00:00',
        mobile_default_view_type: config?.mobile_default_view_type ?? null,
        mobile_view: config?.mobile_view ?? null,
        readonly_fields: null,
        slots: config?.slots ?? 2,
        spanned_event_placement: config?.spanned_event_placement ?? 'TimeSlot',
        working_days: config?.working_days ?? [1, 2, 3, 4, 5],
        working_hours: config?.working_hours ?? ['00:00:00', '24:00:00'],
        resource_groups: [
          {
            id: 1,
            name: 'Resource',
            description: 'Resource',
            allResources: () => of([resource]),
            searchResources: () => of([resource]),
            resourceById: () => of(resource),
          },
        ],
        acl: {
          add: false as boolean,
          edit_series: false as boolean,
          edit_occurrence: false,
          resize: config?.acl?.resize !== undefined ? config.acl.resize : !!config?.updateMutation,
          drag: config?.acl?.drag !== undefined ? config.acl.drag : !!config?.updateMutation,
          delete_series: false,
          delete_occurrence: false,
          recurrence: false,
          actions: null,
        },
        hidden_fields: {
          editor: [],
          quickInfo: ['ResourceGroup_1'],
        },
        custom_form_settings: undefined as CustomFormSettings | undefined,
      } satisfies RcgView;

      const formSettings = await this.mapFormSettings(this.config()?.formSettings, this.config()?.eventData);
      view.custom_form_settings = formSettings;
      if (formSettings?.edit?.enabled) {
        view.acl.edit_series = true;
      }
      if (formSettings?.insert?.enabled) {
        view.acl.add = true;
      }
      return view;
    },
    { initialValue: null },
  );

  private async mapFormSettings(
    settings: CalendarFormSettings | undefined,
    eventPaths: MapEventData | undefined,
  ): Promise<CustomFormSettings | undefined> {
    if (!settings || !settings.formId) return undefined;
    if (!settings.edit?.enabled && !settings.insert?.enabled) return undefined;
    const result: CustomFormSettings = {
      formId: settings.formId,
    };

    if (settings.insert?.enabled && eventPaths?.start && eventPaths.end) {
      result.insert = {
        icon: settings.insert?.icon ?? 'add',
        enabled: true,
        dialogTitle: settings.insert?.dialogTitle
          ? await firstValueFrom(tr(settings.insert.dialogTitle))
          : await firstValueFrom(tr('add_event')),
        startDatePath: eventPaths.start,
        endDatePath: eventPaths.end,
        prefill: settings.insert.prefill,
      };
    }

    if (settings.edit?.enabled) {
      result.edit = {
        icon: settings.edit?.icon ?? 'edit',
        enabled: true,
        dialogTitle: settings.edit?.dialogTitle
          ? await firstValueFrom(tr(settings.edit?.dialogTitle))
          : await firstValueFrom(tr('edit_event')),
        prefill: settings.edit.prefill,
      };
    }
    return result.insert || result.edit ? result : undefined;
  }
}
