import { CdkDrag, CdkDragEnd, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EmbeddedViewRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApolloError } from '@apollo/client/core';
import { UserSettingsService } from '@rcg/core';
import { FormDialogService } from '@rcg/forms';
import { IntlModule, IntlService, tr } from '@rcg/intl';
import { MessageService, decodeHtmlEntities, deepDistinctUntilChanged, getContrastYIQ } from '@rcg/standalone';
import { delayedRefCount, isNonNullable } from '@rcg/utils';
import { DateTimePickerComponent, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownTreeModule } from '@syncfusion/ej2-angular-dropdowns';
import {
  ActionEventArgs,
  AgendaService,
  DayService,
  DragAndDropService,
  DragEventArgs,
  EventRenderedArgs,
  EventSettingsModel,
  GroupModel,
  MonthAgendaService,
  MonthService,
  NavigatingEventArgs,
  ResizeEventArgs,
  ResizeService,
  ScheduleComponent,
  ScheduleModule,
  TimelineMonthService,
  TimelineViewsService,
  View,
  WeekService,
  WorkWeekService,
  generateSummary,
} from '@syncfusion/ej2-angular-schedule';
import { DateFormatOptions, Internationalization, L10n, closest } from '@syncfusion/ej2-base';
import { endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns';
import { isMobile } from 'is-mobile';
import moment from 'moment-timezone';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  catchError,
  combineLatest,
  combineLatestWith,
  connectable,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  merge,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { ICalendarService, RcgCalendarAcl, RcgView, defaultRcgCalendarAcl } from '../../models';
import { RcgCalendarEventException, RcgEvent, RcgUnassignedEvent } from '../../models/event.model';
import { RcgResource, RcgResourceGroup } from '../../models/resource.model';
import { RcgSFPopupUtil } from '../../util/popup';
import { exportToPdf } from './pdf-export';

const logLabel = '[Calendar]';
const dayMilliseconds = 24 * 60 * 60 * 1000;

const eventDraggedFlagSymbol = Symbol('dragged');
const eventResizedFlagSymbol = Symbol('resized');

interface CalendarUserSettings {
  showResourceGroups?: number[] | null;
}

const selector = 'rcg-calendar-container';

@Component({
  selector,
  templateUrl: './calendar-container.component.html',
  styleUrls: ['./calendar-container.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    IntlModule,
    DateTimePickerModule,
    DropDownTreeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTooltipModule,
    ScheduleModule,
    CdkDrag,
  ],
  providers: [
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    MonthAgendaService,
    TimelineViewsService,
    TimelineMonthService,
    ResizeService,
    DragAndDropService,
  ],
})
export class CalendarContainerComponent implements OnChanges, AfterViewInit, OnDestroy {
  public readonly selector = selector;

  public readonly isMobile = isMobile({ tablet: true, featureDetect: true });

  @ViewChild('schedule') schedule?: ScheduleComponent;

  @Input() viewData: RcgView | null = null;
  @Input() hideModifications = false;

  @Input() getExtraEventInfoPopupButtons?: ConstructorParameters<typeof RcgSFPopupUtil>[6];

  @ViewChild('searchTemplate') searchTemplate?: TemplateRef<unknown>;
  @ViewChild('resourcePickerTemplate') resourcePickerTemplate?: TemplateRef<unknown>;
  @ViewChild('unassignedDragOverlayContainer') unassignedDragOverlayContainer?: ElementRef<HTMLElement>;

  @HostBinding('style.--rcg-sf-drag-offset-x') sfDragOffsetX = '0';
  @HostBinding('style.--rcg-sf-drag-offset-y') sfDragOffsetY = '0';

  public readonly sfLocale$ = inject(IntlService).syncfusionLocale$;

  private readonly _hideModifications$ = new BehaviorSubject<boolean>(false);
  readonly hideModifications$ = this._hideModifications$.asObservable();

  readonly defaultEventSettings: EventSettingsModel = {
    fields: {
      id: 'SFEventId',
    },
  };

  readonly eventSettings$ = new BehaviorSubject<EventSettingsModel>(this.defaultEventSettings);

  private intl = new Internationalization();
  private recurrenceL10n = new L10n('recurrenceeditor', {});
  readonly firstDayOfWeek = 1;

  public loading$ = new BehaviorSubject<boolean>(true);
  public savingEvents$ = new BehaviorSubject<number[]>([]);

  private _forceReload$ = new Subject<null>();
  public forceReload$ = merge(
    this._forceReload$.pipe(map(() => true)),
    this._forceReload$.pipe(
      delay(20),
      switchMap(() =>
        this.loading$.pipe(
          startWith(this.loading$.value),
          filter((l) => l === false),
          take(1),
        ),
      ),
      map(() => false),
    ),
  ).pipe(startWith(true), shareReplay({ bufferSize: 1, refCount: true }));

  private readonly _view$ = new BehaviorSubject<View | undefined>(undefined);
  readonly view$ = this._view$.asObservable().pipe(distinctUntilChanged(), debounceTime(10));

  public readonly search = signal('');
  private readonly search$ = toObservable(this.search);

  public readonly unassignedSearch = signal('');
  private readonly unassignedSearch$ = toObservable(this.unassignedSearch);

  date$ = new BehaviorSubject<Date>(new Date());

  private dataSub?: Subscription;
  private currentDateSub?: Subscription;
  private defaultViewTypeSub?: Subscription;
  private earlyEventsSub?: Subscription;
  private scrollToSub?: Subscription;

  private safeScrollToTimeout?: number;

  private viewData$ = new BehaviorSubject<RcgView | null>(null);
  public readonly rViewData$ = this.viewData$.asObservable();

  readonly defaultRcgCalendarAcl = defaultRcgCalendarAcl;

  readonly acl$ = this.viewData$.pipe(
    map((v) => v?.acl ?? defaultRcgCalendarAcl),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  private readonly _allowAdding = (acl: RcgCalendarAcl) => acl.add || acl.edit_occurrence;
  private readonly _allowEditing = (acl: RcgCalendarAcl) => acl.edit_series || acl.edit_occurrence;
  private readonly _allowDeleting = (acl: RcgCalendarAcl) => acl.delete_series || acl.delete_occurrence;
  private readonly _allowResizing = (acl: RcgCalendarAcl) => acl.resize;
  private readonly _allowDragAndDrop = (acl: RcgCalendarAcl) => acl.drag;
  private readonly _allowRecurrence = (acl: RcgCalendarAcl) => acl.recurrence;

  readonly allowAdding$ = this.acl$.pipe(map(this._allowAdding));
  readonly allowEditing$ = this.acl$.pipe(map(this._allowEditing));
  readonly allowDeleting$ = this.acl$.pipe(map(this._allowDeleting));
  readonly allowResizing$ = this.acl$.pipe(map(this._allowResizing));
  readonly allowDragAndDrop$ = this.acl$.pipe(map(this._allowDragAndDrop));
  readonly allowRecurrence$ = this.acl$.pipe(map(this._allowRecurrence));

  readonly readonlyFields$ = this.viewData$.pipe(map((v) => v?.readonly_fields));
  readonly hiddenFields$ = this.viewData$.pipe(map((v) => v?.hidden_fields));

  readonly allowedViewTypes$ = this.viewData$.pipe(
    map((v) => v?.allowed_view_types ?? []),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly defaultViewType$ = this.viewData$.pipe(
    map((v) => (this.isMobile && v?.mobile_default_view_type ? v.mobile_default_view_type : v?.default_view_type)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly workHours$ = this.viewData$.pipe(
    map((v) => v?.working_hours ?? (['08:00', '22:00'] as const)),
    map((wo) => (wo.length === 2 ? ([...wo, ...wo] as const) : wo)),
    map((wo) => wo.map((w) => (/[0-9]{2}:[0-9]{2}:[0-9]{2}/.test(w) ? w.substring(0, 5) : w))),
    combineLatestWith(this.view$),
    map(([wo, view]) => (view?.startsWith('Work') ? wo.slice(2, 4) : wo.slice(0, 2))),
    map(([start, end]) => ({ start, end })),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly workDays$ = this.viewData$.pipe(
    map((v) => v?.working_days ?? [1, 2, 3, 4, 5]),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly startHour$ = this.workHours$.pipe(
    map((wo) => wo.start),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly endHour$ = this.workHours$.pipe(
    map((wo) => wo.end),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly timeScale$ = this.viewData$.pipe(
    map((v) => ({
      interval: v?.interval ? moment.duration(v.interval).asMinutes() : 60,
      slotCount: v?.slots ?? 2,
    })),
  );

  private readonly userSettings$ = this.viewData$.pipe(
    switchMap((vd) => (vd?.user_settings_key ? this.userSettingsService.calendar$(vd?.user_settings_key) : of(null))),
    map((v) => (v ?? {}) as CalendarUserSettings),
    deepDistinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly resourceGroups$ = new BehaviorSubject<RcgResourceGroup[]>([]);
  private readonly masterResourceGroup$ = new BehaviorSubject<RcgResourceGroup | undefined>(undefined);

  readonly unfilteredResources$ = this.masterResourceGroup$.pipe(
    switchMap((masterResourceGroup) => masterResourceGroup?.allResources() ?? of([])),
    distinctUntilChanged((p, n) => {
      if (p.length !== n.length) return false;

      const pIds = p
        .map((r) => r.id)
        .sort()
        .join(',');

      const nIds = n
        .map((r) => r.id)
        .sort()
        .join(',');

      return pIds === nIds;
    }),
    combineLatestWith(this.userSettings$),
    tap(([r, us]) => {
      if (us.showResourceGroups?.length) this.showResources$.next({ init: true, res: us.showResourceGroups });
      else this.pickAllResources(r, true);
    }),
    map(([r]) => r),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly resourcePickerSearchControl = new FormControl<string>('');
  readonly showResources$ = new BehaviorSubject<{ init: boolean | null; res: number[] | null }>({ init: null, res: null });
  readonly showResourcesDistinct$ = this.showResources$.pipe(distinctUntilChanged(), shareReplay({ refCount: true, bufferSize: 1 }));

  readonly resourcePickState$ = combineLatest([this.showResources$, this.unfilteredResources$]).pipe(
    map(([showResources, allResources]) => ({
      all: showResources.res?.length === allResources.length,
      none: showResources.res?.length === 0,
    })),
    distinctUntilChanged((p, n) => p.all === n.all && p.none === n.none),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly resourcePickerResources$ = combineLatest([
    this.unfilteredResources$,
    this.resourcePickerSearchControl.valueChanges.pipe(startWith(''), debounceTime(100)),
  ]).pipe(
    map(([resources, search]) =>
      search
        ? resources.map((r) => ({ searchMatch: r.name.toLowerCase().includes(search.toLowerCase()), ...r }))
        : resources.map((r) => ({ searchMatch: true, ...r })),
    ),
  );

  async pickAllResources(resources: RcgResource[] | null, init: boolean) {
    const r = resources ? resources : await firstValueFrom(this.unfilteredResources$);
    this.showResources$.next({ init, res: r.map((r) => r.id) });
  }

  onResourcePickAllChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.pickAllResources(null, false);
    } else {
      this.showResources$.next({ init: false, res: [] });
    }
  }

  readonly resources$ = combineLatest([this.unfilteredResources$, this.showResources$]).pipe(
    map(([r, showResources]) => r.filter((res) => showResources.res?.includes(res.id))),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly parentResources$ = this.resources$.pipe(
    map((r) => r.filter((res) => res.isParent)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly childResources$ = this.resources$.pipe(
    map((r) => r.filter((res) => !res.isParent)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly childResourcesCount$ = this.childResources$.pipe(
    map((r) => r.length),
    map((l) => (l === 0 ? 1 : l)),
  );

  readonly hasChildResources$ = this.childResources$.pipe(
    map((r) => !!r.length),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly events$ = combineLatest([
    this.viewData$,
    this.masterResourceGroup$.pipe(map((masterResourceGroup) => masterResourceGroup?.id)),
    this._view$,
    this.date$,
  ]).pipe(
    debounceTime(10),
    switchMap(([viewData, resourceGroupId, view, date]) => {
      if (!viewData || !resourceGroupId) return of([]);

      let start: Date;
      let end: Date;

      switch (view) {
        case 'Week':
        case 'WorkWeek':
        case 'TimelineWeek':
        case 'TimelineWorkWeek':
          start = startOfWeek(date, { weekStartsOn: 1 });
          end = startOfWeek(new Date(start.getTime() + 8 * dayMilliseconds), { weekStartsOn: 1 });
          break;
        case 'Month':
          start = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
          end = new Date(endOfWeek(endOfMonth(date), { weekStartsOn: 1 }).getTime() + dayMilliseconds);
          break;
        case 'Agenda':
        case 'MonthAgenda':
        case 'TimelineMonth':
          start = startOfMonth(date);
          end = startOfMonth(new Date(start.getTime() + 32 * dayMilliseconds));
          break;
        case 'Day':
        case 'TimelineDay':
          start = startOfDay(date);
          end = new Date(start.getTime() + dayMilliseconds);
          break;
        case 'Year':
        case 'TimelineYear':
          start = startOfYear(date);
          end = startOfYear(new Date(start.getTime() + 357 * dayMilliseconds));
          break;
        case undefined:
          return of([]);
        default:
          console.warn(logLabel, 'Unhandled data view type:', view);
          return of([]);
      }

      this.loading$.next(true);

      let errorCount = 0;

      return this.service.getEvents(viewData, resourceGroupId, start, end, this.search$).pipe(
        catchError((error, caught) => {
          console.error(logLabel, 'Error getting events', error);

          if (errorCount > 2) throw error;
          errorCount++;

          return caught.pipe(delay(1000));
        }),
        tap(() => {
          errorCount = 0;
          this.savingEvents$.next([]);
        }),
      );
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly showSearch$ = this.viewData$.pipe(
    map((v) => v?.show?.search),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly showUnassignedEvents$ = this.viewData$.pipe(
    map((v) => v?.show?.unassignedEvents),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly unassignedEvents$ = this.viewData$.pipe(
    switchMap((v) => (v ? combineLatest([this.service.getUnassignedEvents(v, this.unassignedSearch$), this.acl$]) : of([]))),
    map(([events, acl]) =>
      events.map((e) => {
        const aclIds = [...(acl.id ? [acl.id] : []), ...(acl.ids ?? [])];

        return {
          ...e,
          _colorContrastYIQ: getContrastYIQ(e.Color ?? e.status?.color ?? '#A3C3D9'),
          _canAssign: acl.add && (!e.assign_acl_ids || e.assign_acl_ids.some((id) => aclIds.includes(id))),
        };
      }),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly mobileView$ = this.viewData$.pipe(
    map((v) => v?.mobile_view ?? 'sf-compact'),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly enableSfCompactView$ = this.mobileView$.pipe(
    map((v) => this.isMobile && v === 'sf-compact'),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly enableRcgCompactView$ = this.mobileView$.pipe(
    map((v) => this.isMobile && v === 'rcg-compact'),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly group$ = combineLatest([this.enableSfCompactView$, this.parentResources$, this.childResources$]).pipe(
    debounceTime(10),
    map(
      ([enableCompactView, parentResources, childResources]) =>
        ({
          byDate: true,
          byGroupID: false,
          allowGroupEdit: true,
          resources: [...(parentResources.length ? ['ParentResources'] : []), ...(childResources.length ? ['ChildResources'] : [])],
          enableCompactView,
        } as GroupModel),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly popupUtil = new RcgSFPopupUtil(
    () => this.schedule!,
    this.service,
    this.messageService,
    this.formDialogService,
    (...args) => this.addUpdatingEvents(...args),
    (...args) => this.getResourceGroup(...args),
    (...args) => this.getExtraEventInfoPopupButtons?.(...args) ?? [],
  );

  constructor(
    private vcRef: ViewContainerRef,
    private elRef: ElementRef,
    private service: ICalendarService,
    private messageService: MessageService,
    private formDialogService: FormDialogService,
    private userSettingsService: UserSettingsService,
  ) {
    this.forceReload$.pipe(takeUntilDestroyed()).subscribe(() => {
      //? Keep subscribed for the lifetime of the component
    });

    this.viewData$.pipe(takeUntilDestroyed()).subscribe(() => {
      this._forceReload$.next(null);
    });

    this.showResourcesDistinct$.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (v.init) return;

      if (this.viewData$.value?.user_settings_key) {
        this.userSettingsService.set(`calendar/${this.viewData$.value.user_settings_key}`, {
          showResourceGroups: v.res,
        } satisfies CalendarUserSettings);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('viewData' in changes) {
      this.viewData$.next(this.viewData);
    }
    if ('hideModifications' in changes) {
      this._hideModifications$.next(this.hideModifications);
    }
  }

  async ngAfterViewInit() {
    this.defaultViewTypeSub = this.defaultViewType$.subscribe((v) => this._view$.next(v));

    this.earlyEventsSub = this.events$.subscribe();

    this.viewData$
      .pipe(
        map((viewData) => viewData?.resource_groups ?? []),
        tap((g) => this.masterResourceGroup$.next(g[0])),
      )
      .subscribe(this.resourceGroups$);

    this.dataSub = combineLatest([this.viewData$, this.acl$, this.events$])
      .pipe(debounceTime(10))
      .subscribe(async ([viewData, acl, events]) => {
        await this.setEvents(viewData ?? undefined, acl, events);
        this.loading$.next(false);
      });

    this.currentDateSub = this.viewData$
      .pipe(
        map((v) => (v?.date ? new Date(v.date) : null)),
        filter(isNonNullable),
      )
      .subscribe(this.date$);

    this.scrollToSub = combineLatest([this.workHours$, this.viewData$, this.view$])
      .pipe(debounceTime(100))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe(([workHours, _, currentView]) => {
        this.scrollToPosition(currentView, workHours);
      });
  }

  ngOnDestroy(): void {
    this.scrollToSub?.unsubscribe();
    this.currentDateSub?.unsubscribe();
    this.dataSub?.unsubscribe();
    this.defaultViewTypeSub?.unsubscribe();
    this.earlyEventsSub?.unsubscribe();

    this.loading$.unsubscribe();
    this.savingEvents$.unsubscribe();

    this._view$.unsubscribe();
    this.date$.unsubscribe();

    this.resourceGroups$.unsubscribe();
    this.masterResourceGroup$.unsubscribe();
    this.showResources$.unsubscribe();
    this.resourcePickerView?.destroy();

    this.viewData$.unsubscribe();
    this.eventSettings$.unsubscribe();
  }

  private async safeScrollTo(pos: string, tries = 1) {
    if (this.safeScrollToTimeout) {
      clearTimeout(this.safeScrollToTimeout);
      this.safeScrollToTimeout = undefined;
    }

    if (tries >= 25) {
      console.error('safeScrollTo failed! (too many tries)');
      return;
    }

    const retrySST = () => {
      this.safeScrollToTimeout = window.setTimeout(() => this.safeScrollTo(pos, tries + 1), 200);
    };

    if (!this.schedule) {
      retrySST();
      return;
    }

    const doScrollTo = async (...timeouts: number[]) => {
      for (const t of timeouts) {
        try {
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => {
              try {
                resolve(this.schedule!.scrollTo(pos));
              } catch (error) {
                reject(error);
              }
            }, t),
          );
          break;
        } catch (error) {
          console.warn(`scrollTo error (tries: ${tries}, timeout: ${t})`, error);
        }
      }
    };

    try {
      await doScrollTo(0, 100, 1000);
    } catch (error) {
      if (tries > 24) console.warn(`scrollTo error (tries: ${tries})`, error);
      retrySST();
    }
  }

  private _quickInfoOnLoadOpenEventId: number | null | undefined;

  private async setEvents(viewData: RcgView | undefined, acl: RcgCalendarAcl | undefined, events: RcgEvent[]) {
    this._quickInfoOnLoadOpenEventId = viewData?.open_event_id;

    this.eventSettings$.next({
      ...this.defaultEventSettings,
      allowAdding: acl ? this._allowAdding(acl) : false,
      allowEditing: acl ? this._allowEditing(acl) : false,
      allowDeleting: acl ? this._allowDeleting(acl) : false,
      spannedEventPlacement: viewData?.spanned_event_placement,
      dataSource: events,
    });
  }

  onCreated(schedule: ScheduleComponent) {
    schedule.isAdaptive = false;
    schedule.element.classList.remove('e-device');
  }

  onDataBound(schedule: ScheduleComponent) {
    if (!this._quickInfoOnLoadOpenEventId) return;

    const sfEvent = schedule.eventsData.find((e) => e.Id === this._quickInfoOnLoadOpenEventId);
    if (!sfEvent) return;

    setTimeout(() => schedule.openQuickInfoPopup(sfEvent), 100);
  }

  async onNavigating(args: NavigatingEventArgs) {
    await this.setEvents(undefined, undefined, []);

    if (args.currentView) this._view$.next(args.currentView as View);
    if (args.currentDate) this.date$.next(args.currentDate);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async fixRecordText(record: Record<string, any>): Promise<Record<string, any>> {
    return {
      ...record,
      Subject: await decodeHtmlEntities(record.Subject),
      Description: await decodeHtmlEntities(record.Description),
      Comment: await decodeHtmlEntities(record.Comment),
    };
  }

  private addUpdatingEvents(records: Record<string, unknown>[]) {
    this.savingEvents$.next([...this.savingEvents$.value, ...records.map((r) => r.Id as number)]);
  }

  private removeUpdatingEvent(record: Record<string, unknown>) {
    this.savingEvents$.next(this.savingEvents$.value.filter((id) => id !== record.Id));
  }

  onDragStart() {
    const el = this.elRef.nativeElement as Element;
    const rect = el.getBoundingClientRect();

    this.sfDragOffsetX = `-${rect.x + 8}px`;
    this.sfDragOffsetY = `-8px`;
  }

  onDragStop(args: DragEventArgs) {
    if (closest(args.event.target as Node, '.unassigned-events')) {
      this.eventDroppedOnUnassignedList(args);
      return;
    }

    (args.data as { [eventDraggedFlagSymbol]?: boolean })[eventDraggedFlagSymbol] = true;
  }

  onResizeStop(args: ResizeEventArgs) {
    (args.data as { [eventResizedFlagSymbol]?: boolean })[eventResizedFlagSymbol] = true;
  }

  private searchView: EmbeddedViewRef<unknown> | undefined;
  private resourcePickerView: EmbeddedViewRef<unknown> | undefined;

  private readonly _extraToolbarViews = [
    { viewVar: 'searchView', templateVar: 'searchTemplate', showProp: 'search', showDefault: false },
    { viewVar: 'resourcePickerView', templateVar: 'resourcePickerTemplate', showProp: 'resourcePicker', showDefault: true },
  ] as const;

  private _toolbarItemRendering(args: ActionEventArgs) {
    if (!args.items) return;

    const show = this.viewData$.value?.show ?? {};
    const newItems: typeof args.items = [];

    for (const { viewVar } of this._extraToolbarViews) {
      this[viewVar]?.destroy();
      this[viewVar] = undefined;
    }

    if (show.pdfExport) {
      newItems.push({
        align: 'Left',
        overflow: 'Show',
        prefixIcon: 'e-export-pdf',
        click: () => exportToPdf(this.schedule!.element, this.viewData?.name ?? 'cal'),
      });
    }

    for (const { viewVar, templateVar, showProp, showDefault } of this._extraToolbarViews) {
      if (!(show[showProp] ?? showDefault)) continue;

      const evRef = this.vcRef.createEmbeddedView(this[templateVar]!, null);
      this[viewVar] = evRef;

      const el = document.createElement('div');
      el.style.display = 'flex';
      el.style.flexDirection = 'row';
      el.style.alignItems = 'center';

      el.append(...evRef.rootNodes);

      newItems.push({
        align: 'Left',
        overflow: 'Show',
        template: el,
      });
    }

    args.items = [...args.items.slice(0, 3), ...newItems, ...args.items.slice(3)];
  }

  onActionBegin(args: ActionEventArgs) {
    switch (args.requestType) {
      case 'toolbarItemRendering':
        this._toolbarItemRendering(args);
        break;
    }
  }

  private async _onActionComplete(args: ActionEventArgs) {
    switch (args.requestType) {
      case 'viewNavigate':
      case 'dateNavigate':
      case 'toolBarItemRendered':
        break;
      case 'eventCreated': {
        const records = await Promise.all(args.addedRecords!.map(this.fixRecordText));
        this.addUpdatingEvents(records);
        await Promise.all(records.map((record) => this.service.addEvent(this.viewData$.value!, record)));
        break;
      }
      case 'eventChanged': {
        const records = await Promise.all(args.changedRecords!.map(this.fixRecordText));
        this.addUpdatingEvents(records);

        const dataArr: Record<string, unknown>[] = Array.isArray(args.data) ? args.data : [args.data];

        await Promise.all(
          records.map((record) => {
            if (record.Recurrence) {
              const matchingData = dataArr.find((d) => d['SFEventId'] === record['SFEventId']) as
                | { [eventDraggedFlagSymbol]?: boolean; [eventResizedFlagSymbol]?: boolean }
                | undefined;

              if (matchingData && (matchingData[eventDraggedFlagSymbol] || matchingData[eventResizedFlagSymbol])) {
                this.popupUtil.openNewRecurrenceExceptionEditor(matchingData);
                this.removeUpdatingEvent(matchingData);
                return;
              }
            }

            this.service.updateEvent(this.viewData$.value!, record);
          }),
        );
        break;
      }
      case 'eventRemoved': {
        const records = await Promise.all(args.deletedRecords!.map(this.fixRecordText));
        this.addUpdatingEvents(records);
        await Promise.all(records.map((record) => this.service.deleteEvent(record)));
        break;
      }

      default:
        console.warn(logLabel, 'Unhandled action event:', args);
        break;
    }
  }

  async onActionComplete(args: ActionEventArgs) {
    try {
      await this._onActionComplete(args);
    } catch (error) {
      if (!error || typeof error !== 'object' || (error as { name?: string }).name !== 'ApolloError' || !('graphQLErrors' in error)) {
        throw error;
      }

      const apolloError = error as ApolloError;

      for (const e of apolloError.graphQLErrors) {
        this.messageService.showErrorSnackbar('Napaka:', e.message, 300);
      }
    }
  }

  async onEventRendered(args: EventRenderedArgs) {
    args.element.style.setProperty('--overlap-index', args.data.OverlapIndex);

    const viewData = await firstValueFrom(this.viewData$);
    const crgId = viewData?.color_resource_group_id;

    const resource = async () => {
      let r = await firstValueFrom(
        viewData?.resource_groups.find((g) => g.id == crgId)?.resourceById?.(args.data[`ResourceGroup_${crgId}`]) ?? of(undefined),
      );

      if (!r) {
        r = await firstValueFrom(
          this.resources$.pipe(
            map((resources) =>
              resources.find((r) => r.id == (args.data.AssignedToParentResource ? args.data.ParentResourceId : args.data.ResourceId)),
            ),
          ),
        );
      }

      return r;
    };

    const color = args.data['Color'] ?? args.data.status?.color ?? (await resource())?.color ?? '#A3C3D9';
    const opacity = args.data.status?.opacity ?? 1;

    if (this.schedule!.currentView === 'Agenda') {
      const el = args.element.firstChild as HTMLElement | null;
      if (!el) return;
      el.style.borderLeftColor = color;
    } else {
      const [r, g, b] = [color.slice(1, 3), color.slice(3, 5), color.slice(5, 7)].map((c) => parseInt(c, 16));
      args.element.style.backgroundColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;

      const contrastYIQColor = getContrastYIQ(color);
      args.element.style.setProperty('--bg-contrast-yiq-color', contrastYIQColor);
      args.element.style.color = 'var(--bg-contrast-yiq-color)';
    }
  }

  async deleteException(exception: RcgCalendarEventException) {
    try {
      await this.service.deleteEvent({
        Id: exception.id,
      });
    } catch (error) {
      console.error(logLabel, 'Failed to delete exception:', error);
    }
  }

  isSmallDiff(d1: Date, d2: Date) {
    if (!d1 || !d2) return true;

    return d2.getTime() - d1.getTime() < 60 * 60 * 1000;
  }

  isMediumDiff(d1: Date, d2: Date) {
    if (this.isSmallDiff(d1, d2)) return false;
    return d2.getTime() - d1.getTime() <= 60 * 60 * 1000;
  }

  getIntlString(value: Date, options?: DateFormatOptions) {
    return this.intl.formatDate(value, options).replace(' ', '\u00A0');
  }

  getDateString(value: Date): string {
    if (!value) return '??. ??. ????';
    return this.getIntlString(value, { type: 'date', skeleton: 'short' });
  }

  getTimeString(value: Date): string {
    if (!value) return '??:??';
    return this.getIntlString(value, { type: 'time', skeleton: 'short' });
  }

  getRecurrenceSummary(data: RcgEvent) {
    if (!data.Recurrence) return '';
    const ruleSummary = generateSummary(data.Recurrence!, this.recurrenceL10n, 'sl', 'Gregorian');
    return ruleSummary.charAt(0).toUpperCase() + ruleSummary.slice(1);
  }

  private readonly _resourceAssignmentCache: {
    [identifier: string]: Observable<
      {
        group: RcgResourceGroup;
        resource: RcgResource | undefined;
      }[]
    >;
  } = {};

  private readonly _emptyResourceAssignments$ = of([]);

  getResourceAssignments$(event: RcgEvent) {
    if (!event.Id) return this._emptyResourceAssignments$;

    const identifier = `${event.Id}/${Object.entries(event)
      .filter(([k, v]) => k.startsWith('ResourceGroup_') && v)
      .map(([k, v]) => `${k.substring('ResourceGroup_'.length)}:${v}`)
      .join('/')}`;

    if (this._resourceAssignmentCache[identifier]) return this._resourceAssignmentCache[identifier];

    const ass$ = this.resourceGroups$.pipe(
      switchMap((rgs) =>
        Promise.all(
          rgs.map(async (group) => ({
            group,
            resource: await firstValueFrom(group.resourceById(event[`ResourceGroup_${group.id}`])),
          })),
        ),
      ),
    );

    const conn$ = connectable(ass$, {
      connector: () => new ReplaySubject(1),
      resetOnDisconnect: true,
    });

    const obs$ = conn$.pipe(
      delayedRefCount(3000, () => {
        delete this._resourceAssignmentCache[identifier];
      }),
    );

    return (this._resourceAssignmentCache[identifier] = obs$);
  }

  identifyTemplateData(data: unknown): RcgEvent {
    return data as RcgEvent;
  }

  getResourceGroup(data: RcgEvent, groupId: number) {
    return (
      data[`ResourceGroup_${groupId}`] ??
      (() => {
        const masterResourceGroup = this.masterResourceGroup$.value;
        if (groupId == masterResourceGroup?.id) return data.ResourceId ?? data.ParentResourceId;
        return null;
      })()
    );
  }

  updateEndDTPicker(picker: DateTimePickerComponent, value: Date) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(picker as any).isRendered) return;
    picker.value = moment(value).add({ minutes: 30 }).toDate();
  }

  private scrollToPosition(currentView: View | undefined, workHours: { start: string | undefined } | undefined) {
    if (!currentView) return;
    const timeViews: View[] = ['Day', 'Week', 'WorkWeek'];

    if (timeViews.includes(currentView)) {
      const SCROLL_HOUR_OFFSET = 1;
      const now = new Date();
      const currentHour = now.getHours();
      const hour = currentHour >= SCROLL_HOUR_OFFSET ? currentHour - SCROLL_HOUR_OFFSET : currentHour;
      const scrollTime = `${hour.toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
      this.safeScrollTo(scrollTime);
    } else if (workHours?.start) {
      const d = moment.duration(workHours.start).subtract(1, 'hour');
      const pos = moment.utc(d.as('milliseconds')).format('HH:mm');
      this.safeScrollTo(pos);
    }
  }

  private unassignedOverCellEl?: Element;
  public draggedUnassignedEvent?: RcgUnassignedEvent;
  private unassignedDragOffset = { x: 0, y: 0 };

  private _updateUnassignedEventDragPosition({ x, y }: { x: number; y: number }) {
    const overlayContainerStyle = this.unassignedDragOverlayContainer!.nativeElement.style;

    const hostEl = this.elRef.nativeElement as HTMLElement;
    const hostBounds = hostEl.getBoundingClientRect();

    overlayContainerStyle.left = `${x - hostBounds.left - this.unassignedDragOffset.x}px`;
    overlayContainerStyle.top = `${y - hostBounds.top - this.unassignedDragOffset.y}px`;
    overlayContainerStyle.display = 'block';
  }

  public unassignedEventDragStarted(event: CdkDragStart) {
    this.draggedUnassignedEvent = event.source.data;

    const pos = event.source._dragRef as unknown as {
      _pickupPositionInElement: { x: number; y: number };
      _pickupPositionOnPage: { x: number; y: number };
    };

    this.unassignedDragOffset = pos._pickupPositionInElement;
    this._updateUnassignedEventDragPosition(pos._pickupPositionOnPage);
  }

  public unassignedEventDragMoved(event: CdkDragMove) {
    this._updateUnassignedEventDragPosition(event.pointerPosition);

    const overEl = document
      .elementsFromPoint(event.pointerPosition.x, event.pointerPosition.y)
      .find((el) => el.classList.contains('e-work-cells'));

    if (!overEl) {
      this.unassignedOverCellEl?.classList.remove('rcg-drag-hover');
      this.unassignedOverCellEl = undefined;
      return;
    }

    if (overEl === this.unassignedOverCellEl) return;

    this.unassignedOverCellEl?.classList.remove('rcg-drag-hover');
    overEl.classList.add('rcg-drag-hover');
    this.unassignedOverCellEl = overEl;
  }

  private async _unassignedEventDropped(event: CdkDragEnd) {
    event.source._dragRef.reset();
    this.draggedUnassignedEvent = undefined;
    this.unassignedDragOverlayContainer!.nativeElement.style.display = 'none';

    this.unassignedOverCellEl?.classList.remove('rcg-drag-hover');
    this.unassignedOverCellEl = undefined;

    const droppedEl = document.elementFromPoint(event.dropPoint.x, event.dropPoint.y);
    if (!droppedEl?.classList.contains('e-work-cells')) return;

    const view = this.viewData$.value;
    if (!view) throw new Error('No view data available');

    const cellData = this.schedule!.getCellDetails(droppedEl);
    const resourceDetails = this.schedule!.getResourcesByIndex(cellData.groupIndex!);
    const groupData = resourceDetails.groupData;

    const masterRg = this.masterResourceGroup$.value;

    const hasParent = groupData?.ResourceId && groupData?.ParentResourceId;
    const resId = hasParent ? groupData?.ResourceId : groupData?.ParentResourceId;

    if (!masterRg || !resId) {
      console.error('Invalid resource details', { masterRg, hasParent, resId });
      throw new Error('Invalid resource details');
    }

    await this.service.assignEvent(view, event.source.data, masterRg.id, resId, cellData.startTime, cellData.endTime);
  }

  public async unassignedEventDropped(event: CdkDragEnd) {
    try {
      await this._unassignedEventDropped(event);
    } catch (error) {
      this.messageService.showErrorSnackbar(await firstValueFrom(tr('error')), error);
    }
  }

  public async eventDroppedOnUnassignedList(args: DragEventArgs) {
    try {
      args.cancel = true;

      await this.service.unassignEvent(this.viewData$.value!, args.data as RcgEvent);
    } catch (error) {
      this.messageService.showErrorSnackbar(await firstValueFrom(tr('error')), error);
    }
  }

  public asUnassignedEvent(e: unknown) {
    return e as RcgUnassignedEvent;
  }

  async openUnassignedEvent(event: RcgUnassignedEvent, view: RcgView | null) {
    const formId = view?.custom_form_settings?.formId;
    if (formId && view?.custom_form_settings?.edit?.enabled && event?.Id) {
      this.formDialogService.openForm({
        formMode: 'update',
        formId: formId,
        formrecordId: event.Id,
        dialogTitle: await firstValueFrom(tr('unassigned_event')),
      });
    }
  }
}
