<ng-container
  #scheduleTemplate
  *ngIf="{
    viewData: rViewData$ | async,
    group: group$ | async,
    view: view$ | async,
    allowedViewTypes: allowedViewTypes$ | async,
    acl: acl$ | async,
    readonlyFields: readonlyFields$ | async,
    hiddenFields: hiddenFields$ | async,
    enableSfCompactView: enableSfCompactView$ | async
  } as vars"
>
  <ejs-schedule
    #schedule
    id="schedule"
    *ngIf="(forceReload$ | async) === false"
    class="main"
    height="100%"
    [style.--child-count]="childResourcesCount$ | async"
    [class.rcg-compact-view]="enableRcgCompactView$ | async"
    [locale]="sfLocale$ | async"
    [eventSettings]="eventSettings$ | async"
    [group]="vars.group"
    [currentView]="vars.view"
    [views]="vars.allowedViewTypes"
    [selectedDate]="date$ | async"
    [allowResizing]="allowResizing$ | async"
    [allowDragAndDrop]="allowDragAndDrop$ | async"
    [eventDragArea]="selector"
    [workHours]="workHours$ | async"
    [workDays]="workDays$ | async"
    [startHour]="startHour$ | async"
    [endHour]="endHour$ | async"
    [timeScale]="timeScale$ | async"
    [quickInfoTemplates]="{ content: quickInfoTemplatesContent }"
    [enableAdaptiveUI]="vars.enableSfCompactView ?? false"
    [firstDayOfWeek]="firstDayOfWeek"
    (created)="onCreated(schedule)"
    (dataBound)="onDataBound(schedule)"
    (navigating)="onNavigating($event)"
    (dragStart)="onDragStart()"
    (dragStop)="onDragStop($event)"
    (resizeStop)="onResizeStop($event)"
    (actionBegin)="onActionBegin($event)"
    (actionComplete)="onActionComplete($event)"
    (eventRendered)="onEventRendered($event)"
    (popupOpen)="popupUtil.onPopupOpen($event, vars.viewData!, vars.acl ?? defaultRcgCalendarAcl, vars.readonlyFields, vars.hiddenFields)"
    (popupClose)="popupUtil.onPopupClose($event)"
  >
    <e-resources>
      <e-resource
        field="ParentResourceId"
        title="Parent Resource"
        name="ParentResources"
        [allowMultiple]="false"
        [dataSource]="(parentResources$ | async) ?? []"
        textField="name"
        idField="id"
        colorField="color"
      >
      </e-resource>
      <e-resource
        field="ResourceId"
        title="Resource"
        name="ChildResources"
        [allowMultiple]="false"
        [dataSource]="(childResources$ | async) ?? []"
        textField="name"
        idField="id"
        groupIDField="parent_id"
        colorField="color"
      >
      </e-resource>
    </e-resources>
    <e-views>
      <ng-container *ngFor="let viewType of vars.allowedViewTypes">
        <ng-container [ngSwitch]="viewType">
          <e-view
            *ngSwitchCase="'Week'"
            option="Week"
            [allowVirtualScrolling]="true"
            [eventTemplate]="weekEventTemplate"
            [isSelected]="vars.view === viewType"
          ></e-view>
          <e-view
            *ngSwitchCase="'WorkWeek'"
            option="WorkWeek"
            [allowVirtualScrolling]="true"
            [eventTemplate]="weekEventTemplate"
            [isSelected]="vars.view === viewType"
          ></e-view>

          <e-view *ngSwitchDefault [option]="viewType" [allowVirtualScrolling]="true" [isSelected]="vars.view === viewType"></e-view>
        </ng-container>
      </ng-container>
    </e-views>
  </ejs-schedule>

  @if (showUnassignedEvents$ | async) { @if (unassignedEvents$ | async; as ue) {
  <div class="unassigned-events">
    <h3 style="text-align: center; margin-bottom: 0">{{ 'calendar_unassigned_events_title' | intl }}</h3>

    @if (showSearch$ | async) {
    <mat-form-field style="margin: 0 4px">
      <mat-label>{{ 'search' | intl }}</mat-label>
      <input matInput [ngModel]="unassignedSearch()" (ngModelChange)="unassignedSearch.set($event)" />
      @if (unassignedSearch()) {
      <button matSuffix mat-icon-button aria-label="Clear" (click)="unassignedSearch.set('')">
        <mat-icon>clear</mat-icon>
      </button>
      }
    </mat-form-field>
    }

    <mat-list class="unassigned-events-list">
      @for (e of ue; track $index) {
      <ng-container *ngTemplateOutlet="unassignedListItem; context: { event: e }"></ng-container>
      }
    </mat-list>
  </div>
  } }

  <div
    *ngIf="loading$ | async"
    style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center"
  >
    <mat-spinner mode="indeterminate" [diameter]="32"></mat-spinner>
  </div>
</ng-container>

<ng-template #unassignedListItem let-event="event" let-dragOverlay="dragOverlay">
  @let data = asUnassignedEvent(event);

  <div
    class="unassigned-event-container"
    [class.unassigned-event-drag-overlay]="dragOverlay"
    [class.unassigned-event-assign-not-allowed]="!$any(data)._canAssign"
    [style.backgroundColor]="data.Color ?? data.status?.color ?? '#A3C3D9'"
    [style.color]="$any(data)._colorContrastYIQ"
    [style.opacity]="data.status?.opacity"
    [matTooltip]="$any(data)._canAssign ? null : ('calendar_assign_not_allowed_tooltip' | intl)"
    matTooltipPosition="left"
    cdkDrag
    [cdkDragDisabled]="!$any(data)._canAssign"
    [cdkDragData]="data"
    [cdkDragBoundary]="selector"
    (cdkDragStarted)="unassignedEventDragStarted($event)"
    (cdkDragMoved)="unassignedEventDragMoved($event)"
    (cdkDragEnded)="unassignedEventDropped($event)"
  >
    <div class="unassigned-event" (dblclick)="openUnassignedEvent(data, viewData)">
      <div class="header">
        <span>{{ data.Subject }}</span>
      </div>

      <ng-container>
        <div class="description">{{ data.Description }}</div>
      </ng-container>

      <div class="footer-spacer"></div>

      <div class="footer">
        <div>
          <span
            class="modification-user-initials"
            *ngIf="(hideModifications$ | async) === false && (data.updated_by_user ?? data.created_by_user)"
            [matTooltip]="
              (data.created_by_user
                ? ('created_by' | intl) +
                  ':\n&nbsp;&nbsp;&nbsp;&nbsp;' +
                  data.created_by_user.full_name +
                  (data.created_at ? '\n&nbsp;&nbsp;&nbsp;&nbsp;' + (data.created_at | date : 'short') : '')
                : '') +
              (data.created_by_user && data.updated_by_user && (!data.updated_at || data.updated_at !== data.created_at) ? '\n\n' : '') +
              (data.updated_by_user && (!data.updated_at || data.updated_at !== data.created_at)
                ? ('last_edited_by' | intl) +
                  ':\n&nbsp;&nbsp;&nbsp;&nbsp;' +
                  data.updated_by_user.full_name +
                  (data.updated_at ? '\n&nbsp;&nbsp;&nbsp;&nbsp;' + (data.updated_at | date : 'short') : '')
                : '')
            "
            matTooltipClass="rcg-multiline-mat-tooltip-left"
            >{{ (data.updated_by_user ?? data.created_by_user)!.initials }}</span
          >
        </div>
        <div style="display: flex; align-items: center">
          <mat-spinner mode="indeterminate" class="saving-indicator" [diameter]="16"></mat-spinner>

          <mat-spinner
            *ngIf="data.status?.isInProgress"
            mode="indeterminate"
            class="in-progress-indicator"
            [diameter]="16"
            [title]="data.status!.name_translation | intl"
          ></mat-spinner>

          <mat-icon *ngIf="data.status?.isPending" class="status-icon" [title]="data.status!.name_translation | intl">pending</mat-icon>

          @for (tag of data.Tags; track $index) {
          <div
            class="event-tag"
            [style.--tag-color]="tag.color"
            [matTooltip]="tag.text"
            matTooltipClass="rcg-multiline-mat-tooltip-left"
          ></div>
          }
        </div>
      </div>

      <div
        *ngIf="data.status?.crossedOut"
        style="position: absolute; top: 0; left: -8px; right: -8px; bottom: 0; pointer-events: none"
        [style.--crossed-out-color]="data.status!.crossedOut"
      >
        <div
          style="
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
              to bottom right,
              transparent calc(50% - 1px),
              var(--crossed-out-color),
              transparent calc(50% + 1px)
            );
          "
        ></div>
        <div
          style="
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to top right, transparent calc(50% - 1px), var(--crossed-out-color), transparent calc(50% + 1px));
          "
        ></div>
      </div>
    </div>
  </div>
</ng-template>

<div #unassignedDragOverlayContainer class="unassigned-event-drag-overlay-container">
  @if (draggedUnassignedEvent) {
  <ng-container *ngTemplateOutlet="unassignedListItem; context: { event: draggedUnassignedEvent, dragOverlay: true }"></ng-container>
  }
</div>

<ng-template #weekEventTemplate let-data>
  <div
    class="week-event-template-container"
    [class.is-block]="data.IsBlock"
    [class.small-diff]="isSmallDiff(data.StartTime, data.EndTime)"
    [class.medium-diff]="isMediumDiff(data.StartTime, data.EndTime)"
    [class.is-parent-assigned]="data.AssignedToParentResource && (hasChildResources$ | async)"
    [class.is-saving]="data.IsSaving || (savingEvents$ | async)?.includes(data.Id)"
    [class.is-overlapping]="data.OverlappedBy?.length && (hasChildResources$ | async)"
    [style.opacity]="data.status?.opacity"
  >
    <div class="header">
      <span>{{ data.Subject }}</span>
    </div>

    <ng-container *ngIf="!data.IsBlock">
      <div class="time">
        {{ getDateString(data.StartTime) }} {{ getTimeString(data.StartTime) }}&nbsp;-&nbsp;@if(getDateString(data.StartTime) ===
        getDateString(data.EndTime)) { {{ getTimeString(data.EndTime) }}} @else {
        {{ getDateString(data.EndTime) }}
        {{ getTimeString(data.EndTime) }}
        }
      </div>
      <div class="description">{{ data.Description }}</div>
    </ng-container>

    <div
      *ngIf="data.IsBlock || isSmallDiff(data.StartTime, data.EndTime) || isMediumDiff(data.StartTime, data.EndTime)"
      class="footer-spacer"
    ></div>

    <div class="footer">
      <div>
        <span
          class="modification-user-initials"
          *ngIf="(hideModifications$ | async) === false && (data.updated_by_user ?? data.created_by_user)"
          [matTooltip]="
            (data.created_by_user
              ? ('created_by' | intl) +
                ':\n&nbsp;&nbsp;&nbsp;&nbsp;' +
                data.created_by_user.full_name +
                (data.created_at ? '\n&nbsp;&nbsp;&nbsp;&nbsp;' + (data.created_at | date : 'short') : '')
              : '') +
            (data.created_by_user && data.updated_by_user && (!data.updated_at || data.updated_at !== data.created_at) ? '\n\n' : '') +
            (data.updated_by_user && (!data.updated_at || data.updated_at !== data.created_at)
              ? ('last_edited_by' | intl) +
                ':\n&nbsp;&nbsp;&nbsp;&nbsp;' +
                data.updated_by_user.full_name +
                (data.updated_at ? '\n&nbsp;&nbsp;&nbsp;&nbsp;' + (data.updated_at | date : 'short') : '')
              : '')
          "
          matTooltipClass="rcg-multiline-mat-tooltip-left"
          >{{ (data.updated_by_user ?? data.created_by_user).initials }}</span
        >
      </div>
      <div style="display: flex; align-items: center">
        <mat-spinner mode="indeterminate" class="saving-indicator" [diameter]="16"></mat-spinner>

        <mat-spinner
          *ngIf="data.status?.isInProgress"
          mode="indeterminate"
          class="in-progress-indicator"
          [diameter]="16"
          [title]="data.status.name_translation | intl"
        ></mat-spinner>

        <mat-icon *ngIf="data.status?.isPending" class="status-icon" [title]="data.status.name_translation | intl">pending</mat-icon>

        <mat-icon
          *ngIf="data.OverlappedBy?.length && (hasChildResources$ | async)"
          class="overlap-icon"
          [title]="'event_overlaps_another_event' | intl"
          >warning</mat-icon
        >
        <mat-icon *ngIf="data.IsRecurring && !data.HideRecurrence" class="recurrence-icon" [title]="'event_is_recurring' | intl"
          >sync</mat-icon
        >
        <mat-icon
          *ngIf="data.ExceptionForId && !data.HideRecurrence"
          class="recurrence-icon"
          [title]="'event_is_excepted_from_recurring' | intl"
          >sync_problem</mat-icon
        >

        @for (tag of data.Tags; track $index) {
        <div
          class="event-tag"
          [style.--tag-color]="tag.color"
          [matTooltip]="tag.text"
          matTooltipClass="rcg-multiline-mat-tooltip-left"
        ></div>
        }
      </div>
    </div>

    <div
      *ngIf="data.status?.crossedOut"
      style="position: absolute; top: 0; left: -8px; right: -8px; bottom: 0; pointer-events: none"
      [style.--crossed-out-color]="data.status.crossedOut"
    >
      <div
        style="
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(to bottom right, transparent calc(50% - 1px), var(--crossed-out-color), transparent calc(50% + 1px));
        "
      ></div>
      <div
        style="
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(to top right, transparent calc(50% - 1px), var(--crossed-out-color), transparent calc(50% + 1px));
        "
      ></div>
    </div>
  </div>
</ng-template>

<ng-template #quickInfoTemplatesContent let-untypedData>
  <ng-template [ngIf]="identifyTemplateData(untypedData)" let-data="ngIf">
    <div
      class="e-date-time"
      [style.display]="
        (hiddenFields$ | async)?.quickInfo?.includes('DateTime') &&
        (!data.IsRecurring || (hiddenFields$ | async)?.quickInfo?.includes('RecurrenceSummary'))
          ? 'none'
          : ''
      "
    >
      <div class="e-date-time-icon e-icons"></div>
      <div class="e-date-time-wrapper e-text-ellipsis">
        <div
          class="e-date-time-details e-text-ellipsis"
          [style.display]="(hiddenFields$ | async)?.quickInfo?.includes('DateTime') ? 'none' : ''"
        >
          {{ getDateString(data.StartTime) }} {{ getTimeString(data.StartTime) }}&nbsp;-&nbsp;@if(getDateString(data.StartTime) ===
          getDateString(data.EndTime)) { {{ getTimeString(data.EndTime) }}} @else {
          {{ getDateString(data.EndTime) }}
          {{ getTimeString(data.EndTime) }}
          }
        </div>
        <div
          *ngIf="data.IsRecurring"
          class="e-recurrence-summary e-text-ellipsis"
          [style.display]="(hiddenFields$ | async)?.quickInfo?.includes('RecurrenceSummary') ? 'none' : ''"
        >
          {{ getRecurrenceSummary(data) }}
        </div>
      </div>
    </div>

    <div
      *ngIf="data.Description"
      class="e-description"
      [style.display]="(hiddenFields$ | async)?.quickInfo?.includes('Description') ? 'none' : ''"
    >
      <div class="e-description-icon e-icons"></div>
      <div class="e-description-details e-text-ellipsis">{{ data.Description }}</div>
    </div>
    <div *ngIf="data.Id" class="e-description" [style.display]="(hiddenFields$ | async)?.quickInfo?.includes('Id') ? 'none' : ''">
      <mat-icon>tag</mat-icon>
      <div class="e-description-details e-text-ellipsis">{{ data.Id }}</div>
    </div>
    <div *ngIf="data.Comment" class="e-description" [style.display]="(hiddenFields$ | async)?.quickInfo?.includes('Comment') ? 'none' : ''">
      <div class="e-description-icon e-icons comment-icon"></div>
      <div class="e-description-details e-text-ellipsis">{{ data.Comment }}</div>
    </div>
    <div
      *ngFor="let assignment of getResourceAssignments$(data) | async"
      class="e-resource"
      [style.display]="(hiddenFields$ | async)?.quickInfo?.includes('ResourceGroup_' + assignment.group.id) ? 'none' : ''"
    >
      <div class="e-resource-icon e-icons"></div>
      <div class="e-resource-details e-text-ellipsis">{{ assignment.group.name }}: {{ assignment.resource?.name ?? 'N/A' }}</div>
    </div>
    <div
      *ngIf="(hideModifications$ | async) === false && data.created_by_user"
      class="e-resource"
      [style.display]="(hiddenFields$ | async)?.quickInfo?.includes('CreatedBy') ? 'none' : ''"
    >
      <div class="e-resource-icon e-icons"></div>
      <div class="e-resource-details e-text-ellipsis">
        {{ 'created_by' | intl }}: {{ data.created_by_user.full_name
        }}{{ data.created_at ? ', ' + (data.created_at | date : 'short') : '' }}
      </div>
    </div>
    <div
      *ngIf="(hideModifications$ | async) === false && data.updated_by_user && (!data.updated_at || data.updated_at !== data.created_at)"
      class="e-resource"
      [style.display]="(hiddenFields$ | async)?.quickInfo?.includes('LastUpdatedBy') ? 'none' : ''"
    >
      <div class="e-resource-icon e-icons"></div>
      <div class="e-resource-details e-text-ellipsis">
        {{ 'last_edited_by' | intl }}: {{ data.updated_by_user.full_name
        }}{{ data.updated_at ? ', ' + (data.updated_at | date : 'short') : '' }}
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #searchTemplate>
  <mat-form-field style="width: 300px">
    <mat-label>{{ 'search' | intl }}</mat-label>
    <input matInput [ngModel]="search()" (ngModelChange)="search.set($event)" />
    @if (search()) {
    <button matSuffix mat-icon-button aria-label="Clear" (click)="search.set('')">
      <mat-icon>clear</mat-icon>
    </button>
    }
  </mat-form-field>
</ng-template>

<ng-template #resourcePickerTemplate>
  <mat-form-field style="width: 300px">
    <mat-label>{{ 'calendar_resource_picker_label' | intl }}</mat-label>
    <mat-select
      multiple
      [value]="(showResourcesDistinct$ | async)?.res ?? []"
      (valueChange)="showResources$.next({ init: false, res: $event })"
      (opened)="resourcePickerSearchControl.setValue('')"
    >
      <div style="margin-left: 7px; display: flex; flex-direction: row; gap: 4px">
        <mat-checkbox
          *ngIf="resourcePickState$ | async as rps"
          color="primary"
          [matTooltip]="(rps.all ? 'clear_selection' : 'select_all') | intl"
          [checked]="rps.all"
          [indeterminate]="!rps.none && !rps.all"
          (change)="onResourcePickAllChange($event)"
        ></mat-checkbox>

        <mat-form-field style="margin-top: -8px; flex-grow: 1">
          <mat-label>{{ 'search' | intl }}</mat-label>
          <input matInput [formControl]="resourcePickerSearchControl" />
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            [style.visibility]="resourcePickerSearchControl.value ? 'visible' : 'hidden'"
            (click)="resourcePickerSearchControl.setValue('')"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      </div>

      @for (resource of resourcePickerResources$ | async; track resource.id) {
      <mat-option [value]="resource.id" [style.display]="resource.searchMatch ? '' : 'none'">
        <span>{{ resource.name }}</span>
      </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <button
    mat-icon-button
    color="warn"
    *ngIf="!((resourcePickState$ | async)?.all ?? true)"
    [matTooltip]="'calendar_some_resources_hidden_tooltip' | intl"
    matTooltipClass="rcg-multiline-mat-tooltip-left"
    (click)="pickAllResources(null, false)"
  >
    <mat-icon>visibility</mat-icon>
  </button>
</ng-template>
