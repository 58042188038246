import { DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { AuthService } from '@rcg/auth';
import { ICalendarService, RcgCalendarEventException, RcgEvent, RcgUnassignedEvent, RcgView } from '@rcg/calendar';
import { GraphqlClientService } from '@rcg/graphql';
import { tr } from '@rcg/intl';
import { MessageService } from '@rcg/standalone';
import { gql } from 'apollo-angular';
import * as dot from 'dot-object';
import { DocumentNode, getOperationAST } from 'graphql';
import { DateTime } from 'luxon';
import { catchError, combineLatest, debounceTime, filter, firstValueFrom, map, Observable, of, startWith, Subject, switchMap } from 'rxjs';
import { AdditionalQueryConfig, EventData, GqlCalendarConfig, MapEventData } from './models';

export class GqlSimpleCalendarService implements ICalendarService {
  private readonly gqlClient = inject(GraphqlClientService);
  private readonly auth = inject(AuthService);
  private readonly refreshTrigger = new Subject<void>();
  private readonly destroyRef = inject(DestroyRef);
  private readonly messageService = inject(MessageService);

  private idCounter = -10000;

  readonly config = signal<GqlCalendarConfig | undefined>(undefined);
  readonly config$ = toObservable(this.config);

  getEvents(_view: RcgView, resourceGroupId: number, start: Date, end: Date, search$?: Observable<string>): Observable<RcgEvent[]> {
    this.idCounter = -10000; // for ids that are not set, calendar id is required

    const searchStream$ = (search$ ?? of('')).pipe(debounceTime(600));

    const refresh$ = this.refreshTrigger.pipe(
      map(() => undefined),
      startWith(undefined),
    );

    return combineLatest([this.config$, searchStream$, refresh$]).pipe(
      filter(([config]) => !!config),
      switchMap(([config, searchTerm]) => {
        const cConfig = config as GqlCalendarConfig;
        const mainQueryObservable = this.processEvents(
          cConfig.query,
          cConfig.variables,
          cConfig.eventData,
          {
            search: searchTerm,
            searchable: config?.search ?? false,
          },
          resourceGroupId,
          start,
          end,
        );

        if (!cConfig.additionalQueries || cConfig.additionalQueries.length === 0) {
          return mainQueryObservable;
        }

        const additionalQueryObservables = cConfig.additionalQueries.map((additionalQuery) =>
          this.processEvents(
            additionalQuery.query,
            additionalQuery.variables,
            additionalQuery.eventData,
            {
              search: searchTerm,
              searchable: false, // search blocked
            },
            resourceGroupId,
            start,
            end,
            additionalQuery,
          ).pipe(
            catchError((error) => {
              console.error(`Error in additional query:`, error);
              return of([]);
            }),
          ),
        );

        return combineLatest([
          mainQueryObservable.pipe(
            catchError((error) => {
              console.error('Error in main calendar query:', error);
              return of([]);
            }),
          ),
          ...additionalQueryObservables,
        ]).pipe(
          map((allResults) => {
            return allResults.flat();
          }),
          catchError((error) => {
            console.error('Error combining calendar event queries:', error);
            return of([]);
          }),
        );
      }),
      catchError((error) => {
        console.error('Error in main getEvents pipeline:', error);
        return of([]);
      }),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  getEventExceptions(): Observable<RcgCalendarEventException[]> {
    return of([]);
  }

  addEvent(): Promise<void> {
    throw new Error('Event add not implemented.');
  }

  async updateEvent(
    _view: RcgView,
    record: { StartTime: Date | undefined; EndTime: Date | undefined; Id: number | undefined },
  ): Promise<void> {
    if (record?.Id && typeof record.Id === 'number' && record.Id < 0) {
      const msg = await firstValueFrom(tr('update_not_allowed'));
      this.messageService.showWarningSnackbar(msg);
      this.refresh();
      return;
    }
    try {
      if (!record?.Id || !record.StartTime || !record.EndTime) {
        throw new Error('Update event - wrong parameters. Id, startTime, endTime are required.');
      }

      const config = this.config();
      if (!config?.updateMutation) {
        throw new Error('Update event -  no update mutation in settings');
      }

      const updateMutation = typeof config.updateMutation === 'string' ? gql(config.updateMutation) : config.updateMutation;
      const startDate = DateTime.fromJSDate(record.StartTime);
      const endDate = DateTime.fromJSDate(record.EndTime);

      let variables: Record<string, unknown> = {
        id: record.Id,
        dt_start: startDate.toISO(),
        dt_end: endDate.toISO(),
      };

      if (config.updateTimeDifference) {
        const differenceSeconds = endDate.diff(startDate, 'seconds').seconds;
        variables = { ...variables, timeDifference: differenceSeconds };
      }

      await firstValueFrom(
        this.gqlClient.mutate({
          mutation: updateMutation,
          variables,
        }),
      );

      this.refresh();
    } catch (error) {
      this.messageService.showErrorSnackbar('Error update calendar event:', error instanceof Error ? error.message : `${error}`);
      this.refresh();
    }
  }

  deleteEvent(): Promise<void> {
    throw new Error('Event delete not implemented.');
  }

  getUnassignedEvents(_view: RcgView, search$: Observable<string>): Observable<RcgUnassignedEvent[]> {
    const refresh$ = this.refreshTrigger.pipe(
      map(() => undefined),
      startWith(undefined),
    );

    const searchStream$ = (search$ ?? of('')).pipe(debounceTime(600));

    return combineLatest([this.config$, searchStream$, refresh$]).pipe(
      map(([config, searchTerm]) => ({ config, searchTerm })),
      filter((data): data is { config: GqlCalendarConfig; searchTerm: string } => !!data.config),
      switchMap(({ config, searchTerm }) => {
        if (!config.unassigned_events?.query) {
          return of([]);
        }

        const variables = config.unassigned_events?.variables ? this.resolveSpecialVariables(config.unassigned_events.variables) : {};

        if (config.search && searchTerm && config.unassigned_events?.variables && 'search' in config.unassigned_events.variables) {
          variables['search'] = searchTerm ?? '';
        }

        return this.executeGqlQuery<{ data: Array<Record<string, unknown>> }>(config.unassigned_events.query, variables).pipe(
          map((response) => {
            return (response.data || []).map((item) => {
              const event = this.mapEvent(item, config.eventData);
              return {
                Id: event?.id,
                Subject: event?.title,
                Description: event?.description,
                Color: event?.color,
                status: event?.status,
                Comment: event?.comment,
                Tags: undefined,
              } as RcgUnassignedEvent;
            });
          }),
          catchError((error) => {
            console.error('Error in unassigned events query:', error);
            return of([]);
          }),
        );
      }),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  async assignEvent(
    _view: RcgView,
    event: RcgUnassignedEvent,
    _resourceGroupId: number,
    _resourceId: number,
    start: Date,
    end: Date,
  ): Promise<void> {
    try {
      if (!event?.Id) {
        throw new Error('Update unassigned event - Id not set.');
      }

      const config = this.config();
      if (!config?.updateMutation) {
        throw new Error('Update unassigned -  no update mutation in settings');
      }

      const updateMutation = typeof config.updateMutation === 'string' ? gql(config.updateMutation) : config.updateMutation;

      let variables: Record<string, unknown> = {
        id: event.Id,
        dt_start: DateTime.fromJSDate(start).toISO(),
        dt_end: DateTime.fromJSDate(end).toISO(),
      };

      if (config.updateTimeDifference) {
        const startDt = DateTime.fromJSDate(start);
        const endDt = DateTime.fromJSDate(end);
        const differenceSeconds = endDt.diff(startDt, 'seconds').seconds;
        variables = { ...variables, timeDifference: differenceSeconds };
      }

      await firstValueFrom(
        this.gqlClient.mutate({
          mutation: updateMutation,
          variables,
        }),
      );

      this.refresh();
    } catch (error) {
      this.messageService.showErrorSnackbar('Error update unassigned event:', error instanceof Error ? error.message : `${error}`);
      this.refresh();
    }
  }

  async unassignEvent(_view: RcgView, event: RcgEvent): Promise<void> {
    try {
      if (!event?.Id) {
        throw new Error('Update unassigned event - Id not set.');
      }

      const config = this.config();
      if (!config?.updateMutation) {
        throw new Error('UnassignEvent -  no update mutation in settings');
      }

      const updateMutation = typeof config.updateMutation === 'string' ? gql(config.updateMutation) : config.updateMutation;

      let variables: Record<string, unknown> = {
        id: event.Id,
        dt_start: null,
        dt_end: null,
      };

      if (config.updateTimeDifference) {
        variables = { ...variables, timeDifference: null };
      }
      await firstValueFrom(
        this.gqlClient.mutate({
          mutation: updateMutation,
          variables,
        }),
      );

      this.refresh();
    } catch (error) {
      this.messageService.showErrorSnackbar('Error UnassignEvent:', error instanceof Error ? error.message : `${error}`);
      this.refresh();
    }
  }

  private refresh(): void {
    this.refreshTrigger.next();
  }

  private processEvents(
    query: DocumentNode | string,
    variables: Record<string, unknown> | undefined,
    eventData: MapEventData,
    searchable: {
      search: string;
      searchable: boolean;
    },
    resourceGroupId: number,
    start: Date,
    end: Date,
    additionalQueryConfig?: AdditionalQueryConfig,
  ): Observable<RcgEvent[]> {
    type QueryResponse = { data: Record<string, unknown>[] } | { data: { data: Record<string, unknown>[] } };

    const startIsoDt = DateTime.fromJSDate(start).toISO();
    const endIsoDt = DateTime.fromJSDate(end).toISO();

    let resolvedVars: Record<string, unknown>;
    if (additionalQueryConfig?.isFunction === true) {
      let parameters = variables?.['parameters'] ? { ...variables?.['parameters'] } : {};
      parameters = { ...parameters, calendar_start_dt: startIsoDt, calendar_end_dt: endIsoDt };
      resolvedVars = { ...variables, parameters: this.resolveSpecialVariables(parameters) };
    } else {
      resolvedVars = this.resolveSpecialVariables({
        ...(variables ?? {}),
        calendar_start_dt: startIsoDt,
        calendar_end_dt: endIsoDt,
        ...(searchable.searchable ? { search: searchable.search ?? '' } : {}),
      });
    }

    return this.executeGqlQuery<QueryResponse>(query, resolvedVars).pipe(
      map((response) => {
        const responseData = 'data' in response.data ? response.data.data : response.data;
        return (responseData ?? []).map((d: Record<string, unknown>) => {
          if (!d || !eventData) return null;
          const event = this.mapEvent(d, eventData);
          return this.createRcgEvent(event, resourceGroupId);
        });
      }),
      map((events) => events.filter((event: unknown): event is RcgEvent => event !== null)),
      catchError((error) => {
        console.error('Error fetching events from query', error);
        return of([]);
      }),
    );
  }

  private mapEvent(data: Record<string, unknown>, eventData: MapEventData): EventData | null {
    if (!eventData) return null;
    return {
      id: eventData.id ? dot.pick(eventData.id, data) : --this.idCounter,
      title: this.pickValue(data, eventData.title) ?? '',
      description: this.pickValue(data, eventData.description),
      start: this.pickValue(data, eventData.start),
      end: this.pickValue(data, eventData.end),
      color: this.pickValue(data, eventData.color)?.trim() ?? undefined,
      startTimezone: this.pickValue(data, eventData.startTimezone),
      endTimezone: this.pickValue(data, eventData.endTimezone),
      comment: this.pickValue(data, eventData.comment),
      status: eventData.status?.id
        ? {
            id: Number(this.pickValue(data, eventData.status.id)) || undefined,
            description: this.pickValue(data, eventData.status.description),
            color: this.pickValue(data, eventData.status.color),
          }
        : undefined,
    };
  }
  private pickValue(
    data: Record<string, unknown>,
    path: string | Array<{ path: string; separator?: string } | string> | undefined,
  ): string | undefined {
    if (!path) return undefined;
    if (typeof path === 'string') {
      const trimmedPath = path.trim();
      if (!trimmedPath) return undefined;
      return dot.pick(trimmedPath, data) ?? undefined;
    }
    if (Array.isArray(path)) {
      const result = path
        .map((item) => {
          if (typeof item === 'string') {
            return {
              value: dot.pick(item.trim(), data),
              separator: ' ',
            };
          }
          return {
            value: dot.pick(item.path.trim(), data),
            separator: item.separator ?? ' ',
          };
        })
        .filter((item) => item.value)
        .reduce((acc, item, index, array) => acc + item.value + (index < array.length - 1 ? item.separator : ''), '');
      return result || undefined;
    }
    return undefined;
  }

  private resolveSpecialVariables(variables: Record<string, unknown>): Record<string, unknown> {
    return {
      ...variables,
      ...Object.entries(variables)
        .filter(([, v]) => typeof v === 'string' && v.startsWith('$'))
        .reduce(
          (acc, [k, v]) => ({
            ...acc,
            [k]: (v as string).startsWith('$tenantId')
              ? this.auth.tenant()?.id
              : (v as string).startsWith('$userId')
              ? this.auth.user()?.id
              : (v as string).startsWith('$organizationId')
              ? this.auth.tenant()?.organization?.id
              : v,
          }),
          {},
        ),
    };
  }

  private createRcgEvent(event: EventData | null, resourceGroupId: number): RcgEvent | null {
    if (!event?.id || !event?.start || !event?.end) {
      return null;
    }

    const startDate = DateTime.fromISO(event.start).toLocal().toJSDate();
    const endDate = DateTime.fromISO(event.end).toLocal().toJSDate();

    const eventResult = {
      Id: event.id as number,
      Subject: event.title ?? '',
      Description: event.description ?? '',
      Comment: event.comment,
      ResourceGroupId: resourceGroupId,
      ResourceId: 1,
      OriginalResourceId: 1,
      StartTime: startDate,
      EndTime: endDate,
      rruleset: {
        dtstart: startDate.toISOString(),
        dtend: endDate.toISOString(),
        rrule: null,
        exrule: null,
        exdate: null,
        rdate: null,
      },
      ParentResourceId: 1,
      ResourceChildren: [],
      AssignedToParentResource: false,
      RecurrenceID: undefined,
      RecurrenceException: null,
      Recurrence: null,
      IsRecurring: false,
      IsBlock: false,
      ExceptionForId: undefined,
      Exceptions: [],
      Report: undefined,
      Color: event.color?.replace(/^\\x/, '#'),
      status: null,
      ExtResourceGroups: [],
      edit_acl_ids: [],
      delete_acl_ids: [],
      add_occurrence_exception_acl_ids: [],
      ResourceGroup_1: 1,
    } as RcgEvent;
    return eventResult;
  }

  private executeGqlQuery<T>(query: DocumentNode | string, variables?: Record<string, unknown>): Observable<T> {
    if (!query) return of([] as unknown as T);
    const gqlQuery = typeof query === 'string' ? gql(query) : query;
    const operationAST = getOperationAST(gqlQuery);

    if (!operationAST) {
      console.error('Invalid GraphQL document');
      return of([] as unknown as T);
    }

    const queryOptions = {
      query: gqlQuery,
      variables,
    };

    return (operationAST.operation === 'query' ? this.gqlClient.query<T>(queryOptions) : this.gqlClient.subscribe<T>(queryOptions)).pipe(
      catchError((error) => {
        console.error('Error executing GraphQL operation:', error);
        return of([] as unknown as T);
      }),
    );
  }
}
