import { GraphqlClientService } from '@rcg/graphql';
import { DateUtils, assembleRRuleSetString, fixPgRRuleSetText, toPgRRuleSet } from '@rcg/standalone';
import { isNonNullable } from '@rcg/utils';
import { DocumentNode } from 'graphql';
import { Moment, tz } from 'moment-timezone';
import * as rrule from 'rrule';
import { Observable, combineLatest, combineLatestWith, firstValueFrom, isObservable, map, of, shareReplay, switchMap } from 'rxjs';
import {
  ICalendarService,
  RcgCalendarEvent,
  RcgCalendarEventException,
  RcgCalendarUnassignedEvent,
  RcgEvent,
  RcgUnassignedEvent,
  RcgView,
} from '../models';

export class GqlCalendarService implements ICalendarService {
  constructor(
    private readonly gqlClient: GraphqlClientService,
    private readonly subscriptions: {
      eventsSubscription: DocumentNode | Observable<DocumentNode>;
      eventExceptionsSubscription: DocumentNode | Observable<DocumentNode>;
      unassignedEventsSubscription: DocumentNode | Observable<DocumentNode | null> | null;
    },
    private readonly subscriptionOptions: {
      searchEvents: 'like' | 'pure' | null;
      searchUnassignedEvents: 'like' | 'pure' | null;
    },
    private readonly mutations: {
      addEventMutation: DocumentNode | Observable<DocumentNode>;
      updateEventMutation: DocumentNode | Observable<DocumentNode>;
      deleteEventMutation: DocumentNode | Observable<DocumentNode>;
      assignEventMutation: DocumentNode | Observable<DocumentNode>;
      unassignEventMutation: DocumentNode | Observable<DocumentNode>;
    },
    private readonly dataMappers: {
      events: (data: unknown) => RcgCalendarEvent[];
      eventExceptions: (data: unknown) => RcgCalendarEventException[];
      unassignedEvents: (data: unknown) => RcgCalendarUnassignedEvent[];
    },
  ) {}

  getEvents(view: RcgView, resourceGroupId: number, start: Date, end: Date, search$: Observable<string>) {
    const startIsoDateStr = start.toISOString().split('T')[0];
    const endIsoDateStr = end.toISOString().split('T')[0];

    //const resources$ = of(view.resource_groups.flatMap((rg) => rg.resources));

    const events$ = (
      isObservable(this.subscriptions.eventsSubscription)
        ? this.subscriptions.eventsSubscription
        : of(this.subscriptions.eventsSubscription)
    ).pipe(
      combineLatestWith(this.subscriptionOptions.searchEvents ? search$ : of('')),
      switchMap(([query, search]) =>
        this.gqlClient.subscribe({
          query,
          variables: {
            viewId: view.id,
            resourceGroupId,
            start: startIsoDateStr,
            end: endIsoDateStr,
            ...(this.subscriptionOptions.searchEvents
              ? { search: this.subscriptionOptions.searchEvents === 'like' ? `%${search}%` : search }
              : {}),
          },
        }),
      ),
      map(this.dataMappers.events),
    );

    const resourcedAssignmentEvents$ = events$.pipe(
      map((calendar_events) =>
        calendar_events.map(({ assignments, ...e }) => ({
          ...e,
          assignments: assignments
            .map((a) => {
              const resourceGroup = view.resource_groups.find((rg) => rg.id === a.resource_group_id);

              if (!resourceGroup) {
                console.warn('Ignoring assignment with invalid resource group:', e, a);
                return null;
              }

              return {
                ...a,
                resource$: resourceGroup.resourceById(a.resource_id ?? a.ext_resource_id),
              };
            })
            .filter(isNonNullable),
        })),
      ),
    );

    const occuredEvents$ = resourcedAssignmentEvents$.pipe(
      map((events) =>
        events.map((event) => {
          const masterAssignment = event.assignments?.find((a) => a.resource_group_id === resourceGroupId);

          if (!masterAssignment?.resource$) {
            console.warn('Ignoring event without master assignment resource observable:', resourceGroupId, event);
            return of([]);
          }

          return masterAssignment.resource$.pipe(
            map((resource) => {
              if (!resource) {
                console.warn('Ignoring event without master assignment resource:', resourceGroupId, event);
                return [];
              }

              const zonifiedStart = tz(event.rruleset.dtstart, resource.timezone);
              const zonifiedEnd = tz(event.rruleset.dtend, resource.timezone);

              const diff = zonifiedEnd.diff(zonifiedStart);

              const occurrences: Moment[] = [];

              const rRuleText = fixPgRRuleSetText(event.rruleset_text.rrule, resource.timezone);
              const exRuleText = fixPgRRuleSetText(event.rruleset_text.exrule, resource.timezone);

              if (rRuleText) {
                const rRuleSetString = assembleRRuleSetString(event.rruleset, rRuleText, exRuleText, 'UTC');

                const rRuleSet = rrule.rrulestr(rRuleSetString, { forceset: true }) as rrule.RRuleSet;

                occurrences.push(
                  ...rRuleSet.between(start, end).map((d) => tz(DateUtils.removeTimezone(d.toISOString()), resource.timezone)),
                );
              } else {
                occurrences.push(tz(event.rruleset.dtstart, resource.timezone));
              }

              return occurrences.map((occurrence) => {
                let ResourceId: number | null = resource.id;
                let ParentResourceId = resource.parent_id;
                let ResourceChildren: number[] | undefined;
                let AssignedToParentResource = false;

                if (!resource.parent_id) {
                  ParentResourceId = ResourceId;
                  ResourceId = resource.children?.[0] ?? null;
                  ResourceChildren = resource.children;
                  AssignedToParentResource = true;
                }

                const start = occurrence;
                const end = start.clone().add({ milliseconds: diff });

                const rRule = event.rruleset.rrule;
                const IsRecurring =
                  rRule && ((rRule.count ?? 0) > 1 || rRule.until != null || (rRule.count == null && rRule.until == null));

                const assignments = event.assignments
                  .map((a) => ({ [`ResourceGroup_${a.resource_group_id}`]: a.ext_resource_id ?? a.resource_id }))
                  .reduce((a, e) => ({ ...a, ...e }));

                const extResourceGroups = view.resource_groups.filter((rg) => rg.ext_config);

                const status = {
                  ...event.status,
                  color: event.status?.color?.replace(/^\\x/, '#'),
                  isPending: event.status?.designs?.includes('pending'),
                  crossedOut: event.status?.designs?.find((d) => d.startsWith('crossed_out_'))?.substring(12),
                  isInProgress: event.status?.designs?.includes('in_progress'),
                  opacity: parseFloat(event.status?.designs?.find((d) => d.startsWith('opacity_'))?.substring(8) ?? '') || undefined,
                };

                return this.getEventExceptions(event.id).pipe(
                  map(
                    (Exceptions) =>
                      ({
                        rruleset: event.rruleset,
                        Id: event.id,
                        ResourceGroupId: resourceGroupId,
                        OriginalResourceId: ResourceId,
                        ResourceId,
                        ParentResourceId,
                        ResourceChildren,
                        AssignedToParentResource,
                        StartTime: start.toDate(),
                        EndTime: end.toDate(),
                        Subject: event.title,
                        Description: event.description,
                        Comment: event.comment,
                        Tags: event.tags,
                        Color: event.color?.replace(/^\\x/, '#'),
                        ResourceTimezone: resource.timezone,
                        Recurrence: rRuleText,
                        RecurrenceException: exRuleText,
                        RecurrenceID: IsRecurring ? event.id : undefined,
                        IsRecurring,
                        HideRecurrence: event.hide_recurrence,
                        IsBlock: event.is_block,
                        ExceptionForId: event.exception_for_id,
                        Exceptions,
                        created_at: event.created_at,
                        updated_at: event.updated_at,
                        created_by_user: event.created_by_user,
                        updated_by_user: event.updated_by_user,
                        edit_acl_ids: event.edit_acl_ids,
                        delete_acl_ids: event.delete_acl_ids,
                        add_occurrence_exception_acl_ids: event.add_occurrence_exception_acl_ids,
                        status,
                        ...assignments,
                        ExtResourceGroups: extResourceGroups,
                        extra: event.extra,
                        Report: event.report,
                      } as RcgEvent),
                  ),
                );
              });
            }),
          );
        }),
      ),
      switchMap((events) => (events.length ? combineLatest(events) : of([]))),
      map((events2d) => events2d.flat()),
      switchMap((events) => (events.length ? combineLatest(events) : of([]))),
    );

    return occuredEvents$.pipe(
      map((events) =>
        events.map((event) => {
          const startTime = event.StartTime.getTime();
          const endTime = event.EndTime.getTime();

          const FullOverlap = events.filter(
            (e) =>
              (event.AssignedToParentResource || e.AssignedToParentResource) &&
              e.StartTime.getTime() < endTime &&
              e.EndTime.getTime() > startTime,
          );
          const OverlappedBy = FullOverlap.filter((e) => e !== event);
          const OverlapIndex = FullOverlap.indexOf(event);

          return {
            ...event,
            OverlappedBy,
            OverlapIndex: OverlapIndex === -1 ? 0 : OverlapIndex,
          } as RcgEvent;
        }),
      ),
    );
  }

  getEventExceptions(eventId: number) {
    return (
      isObservable(this.subscriptions.eventExceptionsSubscription)
        ? this.subscriptions.eventExceptionsSubscription
        : of(this.subscriptions.eventExceptionsSubscription)
    ).pipe(
      switchMap((query) =>
        this.gqlClient.subscribe({
          query,
          variables: {
            id: eventId,
          },
        }),
      ),
      map(this.dataMappers.eventExceptions),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private getAssignments(record: RcgEvent, isUpdate: boolean, isDeleted: boolean) {
    const assignments = Object.entries(record)
      .filter(([k]) => k.startsWith('ResourceGroup_'))
      .map(([k, v]) => [+k.substring(14), v] as [number, unknown])
      .map(([resource_group_id, resource_id]) => {
        const d: {
          event_id?: number;
          resource_group_id: number;
          resource_id?: unknown;
          ext_resource_id?: unknown;
        } = {
          event_id: record.Id,
          resource_group_id,
          resource_id,
          ext_resource_id: resource_id,
        };

        if (!isUpdate && (!d.event_id || isDeleted)) delete d.event_id;

        const isExt = record.ExtResourceGroups?.find((r) => r.id === resource_group_id);

        if (isExt) delete d.resource_id;
        else delete d.ext_resource_id;

        return d;
      });

    const setAssignments = assignments.filter((a) => a.resource_id || a.ext_resource_id);

    const deleteAssignments = assignments
      .filter((a) => !a.resource_id && !a.ext_resource_id)
      .map((a) => ({
        resource_group_id: { _eq: a.resource_group_id },
      }));

    return {
      setAssignments,
      deleteAssignments,
    };
  }

  async addEvent(view: RcgView, record: Record<string, unknown>, isDeleted = false) {
    await firstValueFrom(
      this.gqlClient.mutate({
        mutation: await firstValueFrom(
          isObservable(this.mutations.addEventMutation) ? this.mutations.addEventMutation : of(this.mutations.addEventMutation),
        ),
        variables: {
          viewId: view.id,
          rruleset: toPgRRuleSet(record),
          title: record.Subject,
          description: record.Description,
          comment: record.Comment,
          exception_for_id: record.ExceptionForId ? record.ExceptionForId : null,
          isDeleted,
          assignments: this.getAssignments(record as unknown as RcgEvent, false, isDeleted).setAssignments,
          extra: record.extra,
          report: record.Report,
          form_id: record.form_id,
        },
      }),
    );
  }

  async updateEvent(view: RcgView, record: Record<string, unknown>, isDeleted = false) {
    const updateRecord = record;

    const resourceGroupId = record['ResourceGroupId'] as number;
    const originalResourceId = record[`OriginalResourceId`] as number;
    const newResourceId = (Array.isArray(record[`ResourceId`]) ? record[`ResourceId`][0] : record[`ResourceId`]) as number;

    if (newResourceId !== originalResourceId) {
      updateRecord[`ResourceGroup_${resourceGroupId}`] = newResourceId;
    }

    await firstValueFrom(
      this.gqlClient.mutate({
        mutation: await firstValueFrom(
          isObservable(this.mutations.updateEventMutation) ? this.mutations.updateEventMutation : of(this.mutations.updateEventMutation),
        ),
        variables: {
          viewId: view.id,
          id: updateRecord.Id,
          rruleset: toPgRRuleSet(updateRecord),
          title: updateRecord.Subject,
          description: updateRecord.Description,
          comment: updateRecord.Comment,
          isDeleted,
          ...this.getAssignments(updateRecord as unknown as RcgEvent, true, isDeleted),
          extra: record.extra,
          report: updateRecord.Report,
        },
      }),
    );
  }

  async deleteEvent(record: Record<string, unknown>) {
    await firstValueFrom(
      this.gqlClient.mutate({
        mutation: await firstValueFrom(
          isObservable(this.mutations.deleteEventMutation) ? this.mutations.deleteEventMutation : of(this.mutations.deleteEventMutation),
        ),
        variables: {
          id: record.Id,
        },
      }),
    );
  }

  getUnassignedEvents(view: RcgView, search$: Observable<string>): Observable<RcgUnassignedEvent[]> {
    if (!this.subscriptions.unassignedEventsSubscription) return of([]);

    return (
      isObservable(this.subscriptions.unassignedEventsSubscription)
        ? this.subscriptions.unassignedEventsSubscription
        : of(this.subscriptions.unassignedEventsSubscription)
    ).pipe(
      combineLatestWith(this.subscriptionOptions.searchUnassignedEvents ? search$ : of('')),
      switchMap(([query, search]) => {
        if (!query) return of([]);

        return of(query).pipe(
          switchMap((query) =>
            this.gqlClient.subscribe({
              query,
              variables: {
                viewId: view.id,
                ...(this.subscriptionOptions.searchUnassignedEvents
                  ? { search: this.subscriptionOptions.searchUnassignedEvents === 'like' ? `%${search}%` : search }
                  : {}),
              },
            }),
          ),
          map(this.dataMappers.unassignedEvents),
          map((events) =>
            events.map((event) => {
              const status = {
                ...event.status,
                color: event.status?.color?.replace(/^\\x/, '#'),
                isPending: event.status?.designs?.includes('pending'),
                crossedOut: event.status?.designs?.find((d) => d.startsWith('crossed_out_'))?.substring(12),
                isInProgress: event.status?.designs?.includes('in_progress'),
                opacity: parseFloat(event.status?.designs?.find((d) => d.startsWith('opacity_'))?.substring(8) ?? '') || undefined,
              };

              return {
                Id: event.id,
                Subject: event.title,
                Description: event.description,
                Comment: event.comment,
                Tags: event.tags,
                Color: event.color?.replace(/^\\x/, '#'),
                created_at: event.created_at,
                updated_at: event.updated_at,
                created_by_user: event.created_by_user,
                updated_by_user: event.updated_by_user,
                assign_acl_ids: event.assign_acl_ids,
                status,
                extra: event.extra,
              } as RcgUnassignedEvent;
            }),
          ),
        );
      }),
    );
  }

  async assignEvent(view: RcgView, event: RcgUnassignedEvent, resourceGroupId: number, resourceId: number, start: Date, end: Date) {
    const record: Record<string, unknown> = {
      StartTime: start,
      EndTime: end,
      [`ResourceGroup_${resourceGroupId}`]: resourceId,
    };

    await firstValueFrom(
      this.gqlClient.mutate({
        mutation: await firstValueFrom(
          isObservable(this.mutations.assignEventMutation) ? this.mutations.assignEventMutation : of(this.mutations.assignEventMutation),
        ),
        variables: {
          viewId: view.id,
          id: event.Id,
          rruleset: toPgRRuleSet(record),
          assignments: this.getAssignments(record as unknown as RcgEvent, false, false).setAssignments,
        },
      }),
    );
  }

  async unassignEvent(view: RcgView, event: RcgEvent) {
    await firstValueFrom(
      this.gqlClient.mutate({
        mutation: await firstValueFrom(
          isObservable(this.mutations.unassignEventMutation)
            ? this.mutations.unassignEventMutation
            : of(this.mutations.unassignEventMutation),
        ),
        variables: {
          viewId: view.id,
          id: event.Id,
        },
      }),
    );
  }
}
