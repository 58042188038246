import { gql } from '@apollo/client/core';

export const assignEventMutation = gql`
  mutation AssignEventMutation($viewId: bigint!, $id: bigint!, $rruleset: rruleset!, $assignments: jsonb!) {
    sqlFunctionMutation(
      functionName: "calendar.assign_event"
      parameters: { view_id: $viewId, id: $id, rruleset: $rruleset, assignments: $assignments }
    ) {
      __typename
    }
  }
`;
